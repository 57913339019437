import { CohostsResponse } from 'lib/models/cohost';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';

export function getOpportunityAcceptedCohosts(
  cohost_applications: CohostsResponse
) {
  return parseArrayResponse(cohost_applications)
    .filter((cohostApplication) => cohostApplication.status === 'accepted')
    .map((cohostApplication) => {
      const { cohost: cohostResponse } = cohostApplication;
      return parseResponse(cohostResponse);
    });
}
