import cx from 'classnames';

import { Icon, Link } from 'components';
import Avatar from 'components/Avatar';
import { IconSize } from 'components/Icon';
import { AvatarSize } from 'components/Avatar/Avatar.component';
import { UserAvatarFields } from 'lib/models/User.model';

import styles from './HuzzleUserAvatar.module.scss';

type HuzzleUserAvatarProps = {
  className?: string;
  user: UserAvatarFields | null;
  url?: string;
  tickSize?: IconSize;
  size?: AvatarSize;
};

const HuzzleUserAvatar = ({
  user,
  className,
  url = '',
  size = 'large',
  tickSize = 'medium',
}: HuzzleUserAvatarProps) => {
  if (!user) return null;

  const { name, image_url, is_huzzle_verified, is_verified } = user;
  return (
    <Link
      href={url}
      className={cx(styles['avatar-container'], className, {
        [styles.xsmall]: size === 'xsmall',
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
        [styles.size80]: size === '80',
      })}
    >
      <Avatar
        className={cx(styles.avatar, {
          [styles['pointer-cursor']]: url,
        })}
        size={size}
        name={name}
        icon={image_url || ''}
      />
      {is_huzzle_verified && (
        <Icon
          iconName="huzzle-verified"
          className={styles['verified-tag']}
          size={tickSize}
        />
      )}
      {!is_huzzle_verified && is_verified && (
        <Icon
          iconName="tick-mark-circle"
          className={styles['verified-tag']}
          size={tickSize}
        />
      )}
    </Link>
  );
};

HuzzleUserAvatar.displayName = 'HuzzleUserAvatar';

export default HuzzleUserAvatar;
