import { TOpportunity } from 'lib/models/opportunity';
import { TouchpointType } from 'lib/models/touchpoint';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import { JobTouchpointable } from 'lib/models/job';
import { CompanyResponse, TCompany } from 'lib/models/company';
import { EventTouchpointable } from 'lib/models/event';
import {
  StudentSocietyResponse,
  TStudentSociety,
} from 'lib/models/student-society';
import { PAGE_ROUTES } from 'lib/page-routes';
import { default_event_icon_url } from 'utils/urls';
import { parseOpportunityAiFaqs } from './opportunity';

export type EmploymentType =
  | 'FULL_TIME'
  | 'PART_TIME'
  | 'CONTRACTOR'
  | 'TEMPORARY'
  | 'INTERN'
  | 'VOLUNTEER'
  | 'PER_DIEM'
  | 'OTHER';
enum CredentialCategoryEnum {
  BACHELOR = 'bachelor degree',
  POSTGRADUATE = 'postgraduate degree',
}

export const getJobPostingSchemaScript = ({
  opportunity,
}: {
  opportunity: TOpportunity;
}) => {
  const { touchpointable_type, processed_json_schema } = opportunity;
  if (processed_json_schema) {
    return JSON.stringify(processed_json_schema, null, 2);
  }

  const isJob = touchpointable_type === TouchpointType.Job;

  const getSchemaDescription = () => {
    const {
      ai_summary = '',
      description,
      requirements: old_requirements,
      new_requirements,
      responsibilities: old_responsibilities,
      new_responsibilities,
      benefits,
    } = opportunity;
    const requirements =
      new_requirements?.length > 0 ? new_requirements : old_requirements;
    const responsibilities =
      new_responsibilities?.length > 0
        ? new_responsibilities
        : old_responsibilities;
    const sections = [
      { title: 'Summary', value: ai_summary },
      { title: '', value: description },
      { title: 'Requirements', value: requirements },
      { title: 'Responsibilities', value: responsibilities },
      { title: 'Benefits', value: benefits },
    ];
    let fullDescription = '';
    sections.forEach(({ title, value }) => {
      if (Array.isArray(value) && value.length) {
        const listItems = value.map((item) => `<li>${item}</li>`).join('');
        fullDescription +=
          '<div><strong>' +
          (title ? title + ': ' : '') +
          '</strong><ul>' +
          listItems +
          '</ul></div>';
      } else if (value) {
        fullDescription +=
          '<div><strong>' +
          (title ? title + ': ' : '') +
          '</strong><div>' +
          value +
          '</div></div>';
      }
    });
    return '<div>' + fullDescription + '</div>';
  };

  const getSchema = () => {
    const {
      title,
      posted_at,
      created_at,
      touchpointable,
      pay_currency,
      creatable_for,
      cities: citiesResponse,
      is_online,
      remote_countries: remoteCountriesResponse,
      application_deadline,
      start_date,
      education_requirements: educationRequirementsResponse,
      apply_url,
    } = opportunity;
    const { maximum_salary, minimum_salary, full_time } = parseResponse(
      touchpointable as JobTouchpointable
    );
    const creatableFor = parseResponse(creatable_for as CompanyResponse);
    const locations = parseArrayResponse(citiesResponse).map((city) => {
      const { name, country: countryResponse } = city;
      const country = parseResponse(countryResponse)?.name;
      return { city: name, country };
    });
    const remoteCountries = parseArrayResponse(remoteCountriesResponse);
    const allEducationRequirements = parseArrayResponse(
      educationRequirementsResponse
    );
    const datePostedAt = posted_at || created_at;
    const datePosted = datePostedAt
      ? new Date(datePostedAt).toISOString()
      : null;
    const employment_type = full_time ? 'FULL_TIME' : 'PART_TIME';
    const employmentType: EmploymentType | EmploymentType[] = isJob
      ? employment_type
      : ['INTERN', employment_type];
    const hiringOrganization = creatableFor
      ? {
          '@type': 'Organization',
          name: creatableFor.name,
          sameAs: creatableFor.website,
          logo: creatableFor.icon_url,
        }
      : null;
    const salary =
      minimum_salary && maximum_salary
        ? { minValue: minimum_salary, maxValue: maximum_salary }
        : minimum_salary || maximum_salary
        ? { value: minimum_salary || maximum_salary }
        : null;
    const baseSalary = salary
      ? {
          '@type': 'MonetaryAmount',
          currency: pay_currency || 'GBP',
          value: {
            '@type': 'QuantitativeValue',
            ...salary,
            unitText: 'YEAR',
          },
        }
      : null;
    const jobLocation = locations.length
      ? locations.map((loc) => ({
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            addressLocality: loc.city ?? '',
            addressCountry: loc.country ?? '',
          },
        }))
      : null;
    const jobLocationType = is_online ? 'TELECOMMUTE' : null;
    const applicantLocationRequirements =
      is_online && remoteCountries.length
        ? remoteCountries.map((country) => ({
            '@type': 'Country',
            name: country.name,
          }))
        : null;
    const validThrough = application_deadline
      ? new Date(application_deadline).toISOString()
      : null;
    const jobStartDate = start_date ? new Date(start_date).toISOString() : null;
    const educationRequirements = allEducationRequirements.reduce(
      (list, item) => {
        if (
          (item?.name === 'Bachelors' || item?.name === 'Undergraduate') &&
          !list.find(
            (i) => i.credentialCategory === CredentialCategoryEnum.BACHELOR
          )
        ) {
          list = [
            ...list,
            {
              '@type': 'EducationalOccupationalCredential',
              credentialCategory: CredentialCategoryEnum.BACHELOR,
            },
          ];
        }

        if (
          (item?.name === 'PhD' || item?.name === 'Masters') &&
          !list.find(
            (i) => i.credentialCategory === CredentialCategoryEnum.POSTGRADUATE
          )
        ) {
          list = [
            ...list,
            {
              '@type': 'EducationalOccupationalCredential',
              credentialCategory: CredentialCategoryEnum.POSTGRADUATE,
            },
          ];
        }
        return list;
      },
      [] as Array<{
        '@type': string;
        credentialCategory: string;
      }>
    );
    const identifier = creatableFor
      ? {
          '@type': 'PropertyValue',
          name: creatableFor.name,
          value: creatableFor.id,
        }
      : null;

    return {
      '@context': 'http://schema.org',
      '@type': 'JobPosting',
      title,
      datePosted,
      description: getSchemaDescription(),
      employmentType,
      hiringOrganization,
      baseSalary,
      jobLocation,
      jobLocationType,
      applicantLocationRequirements,
      validThrough,
      jobStartDate,
      educationRequirements: educationRequirements.length
        ? educationRequirements
        : null,
      directApply: apply_url ? false : true,
      identifier,
    };
  };

  const schema = getSchema();
  const strippedObject = Object.fromEntries(
    Object.entries(schema).filter(
      ([, value]) => value !== null && value !== undefined
    )
  );
  const schemaString = JSON.stringify(strippedObject, null, 2);

  return schemaString;
};

export const getEventPostingSchemaScript = ({
  opportunity,
}: {
  opportunity: TOpportunity;
}) => {
  const { processed_json_schema } = opportunity;
  if (processed_json_schema) {
    return JSON.stringify(processed_json_schema, null, 2);
  }

  const getSchema = () => {
    const {
      title,
      touchpointable,
      description,
      is_online,
      place: placeResponse,
      online_link,
      creatable_for,
    } = opportunity;
    const { icon_url, start_date, end_date } = parseResponse(
      touchpointable as EventTouchpointable
    );
    const place = parseResponse(placeResponse);
    const creatableFor =
      creatable_for.data.type === 'company'
        ? parseResponse(creatable_for as CompanyResponse)
        : parseResponse(creatable_for as StudentSocietyResponse);
    const image = icon_url || default_event_icon_url;
    const startDate = start_date ? new Date(start_date).toISOString() : null;
    const endDate = end_date ? new Date(end_date).toISOString() : null;
    const organizer = creatableFor
      ? {
          '@type': 'Organization',
          name: creatableFor.name,
          url: creatableFor.website,
        }
      : null;

    const location = is_online
      ? {
          '@type': 'VirtualLocation',
          url: online_link,
        }
      : {
          '@type': 'Place',
          name: place?.address || '',
          address: {
            '@type': 'PostalAddress',
            ...(place?.street_address && {
              streetAddress: place.street_address || '',
            }),
            ...(place?.address_locality && {
              addressLocality: place.city,
            }),
            postalCode: place?.zipcode ?? '',
            addressRegion: place?.address_locality ?? '',
            addressCountry: place?.country ?? 'UK',
          },
        };

    return {
      '@context': 'http://schema.org',
      '@type': 'Event',
      name: title,
      startDate,
      endDate,
      eventAttendanceMode: is_online
        ? 'https://schema.org/OnlineEventAttendanceMode'
        : 'https://schema.org/OfflineEventAttendanceMode',
      eventStatus: 'https://schema.org/EventScheduled',
      location,
      image,
      description,
      organizer,
    };
  };

  const schema = getSchema();
  const strippedObject = Object.fromEntries(
    Object.entries(schema).filter(
      ([, value]) => value !== null && value !== undefined
    )
  );
  const schemaString = JSON.stringify(strippedObject, null, 2);

  return schemaString;
};

export const getEventBreadcrumbSchema = ({
  opportunity,
}: {
  opportunity: TOpportunity;
}) => {
  const { title, public_url } = opportunity;
  const eventBreadcrumbSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': PAGE_ROUTES.EVENTS_SEARCH,
          name: 'Event',
        },
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': public_url,
          name: title,
        },
      },
    ],
  };

  return JSON.stringify(eventBreadcrumbSchema, null, 2);
};

export const getCompanyBreadcrumbSchema = ({
  company,
}: {
  company: TCompany;
}) => {
  const { name, public_url } = company;
  const eventBreadcrumbSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': PAGE_ROUTES.COMPANIES_SEARCH,
          name: 'Companies',
        },
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': public_url,
          name,
        },
      },
    ],
  };

  return JSON.stringify(eventBreadcrumbSchema, null, 2);
};

export const getCommunityBreadcrumbSchema = ({
  community,
}: {
  community: TStudentSociety;
}) => {
  const { name, public_url } = community;
  const eventBreadcrumbSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': PAGE_ROUTES.SOCIETIES_SEARCH,
          name: 'Societies',
        },
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': public_url,
          name,
        },
      },
    ],
  };

  return JSON.stringify(eventBreadcrumbSchema, null, 2);
};

export const getJobFAQPageSchema = ({
  opportunity,
}: {
  opportunity: TOpportunity;
}) => {
  const { ai_faqs } = opportunity;
  const aiFaqs = parseOpportunityAiFaqs(ai_faqs);

  if (!aiFaqs.length) {
    return null;
  }

  const mainEntity = aiFaqs.map(({ question, answer }) => ({
    '@type': 'Question',
    name: question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: answer,
    },
  }));
  const jobFAQPageSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: mainEntity,
  };
  return JSON.stringify(jobFAQPageSchema, null, 2);
};
