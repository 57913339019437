import cx from 'classnames';
import React, { memo, useMemo } from 'react';
import { differenceInDays, format, isAfter } from 'date-fns';
import { useTranslation } from 'next-i18next';

import { Link, Image, Icon } from 'components';
import { HuzzleUserAvatar } from 'components/HuzzleUserAvatar';
import {
  ApplicationDeadline,
  OpportunitySalary,
  EventApplicants,
  Chip,
} from 'components/ComponentV2';
import { JobTouchpointable } from 'lib/models/job';
import { TouchpointType } from 'lib/models/touchpoint';
import { TOpportunity, TouchpointState } from 'lib/models/opportunity';
import { parseResponse } from 'lib/utils/parser';
import { CompanyResponse } from 'lib/models/company';
import { StudentSocietyResponse } from 'lib/models/student-society';
import { EventTouchpointable } from 'lib/models/event';
import { getOpportunityPublicPagePathname } from 'utils/urls';
import { getUserAvatarFields } from 'utils/user';
import { getOpportunityAcceptedCohosts } from 'utils/cohost';

import styles from './OrganizationOpportunityCard.module.scss';

export interface OrganizationOpportunityCardProps {
  className?: string;
  opportunity: TOpportunity;
  showOrganization?: boolean;
  showMultipleHosts?: boolean;
  showOpportunityType?: boolean;
  showFeaturedTag?: boolean;
}

export const OrganizationOpportunityCard: React.FC<OrganizationOpportunityCardProps> =
  memo(
    ({
      className,
      opportunity,
      showOrganization = true,
      showMultipleHosts = false,
      showOpportunityType = true,
      showFeaturedTag = true,
    }) => {
      const [tc] = useTranslation('common');
      const {
        title,
        slug,
        touchpointable_type,
        state,
        pay_currency,
        application_deadline,
        kind,
        is_online,
        start_date,
        avatar_urls,
        applications,
        public_url,
        featured,
        touchpointable: touchpointableResponse,
        creatable_for: creatableForResponse,
        place: placeResponse,
        cohost_applications: cohostApplicationsResponse,
      } = opportunity;
      const isEvent = touchpointable_type === TouchpointType.Event;
      const isExpired = state === TouchpointState.EXPIRED;
      const url =
        getOpportunityPublicPagePathname(touchpointable_type, slug) ||
        public_url;
      const isEventEnded = useMemo(() => {
        if (touchpointable_type !== TouchpointType.Event) return false;
        if (isExpired) return true;
        const touchpointable = parseResponse(
          touchpointableResponse as EventTouchpointable
        );
        return touchpointable.end_date
          ? isAfter(new Date(), new Date(touchpointable.end_date))
          : false;
      }, [touchpointableResponse, touchpointable_type, isExpired]);
      let minimumSalary = null,
        maximumSalary = null,
        eventBannerUrl = null;

      if (isEvent) {
        const event = parseResponse(
          touchpointableResponse as EventTouchpointable
        );
        eventBannerUrl =
          event.icon_url ||
          `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}/images/huzzle-gradient-20.jpg`;
      } else {
        const job = parseResponse(touchpointableResponse as JobTouchpointable);
        minimumSalary = job.minimum_salary;
        maximumSalary = job.maximum_salary;
      }

      const { company, student_society } = useMemo(() => {
        if (creatableForResponse.data.type === 'company') {
          return {
            company: parseResponse(creatableForResponse as CompanyResponse),
            student_society: null,
          };
        } else {
          return {
            student_society: parseResponse(
              creatableForResponse as StudentSocietyResponse
            ),
            company: null,
          };
        }
      }, [creatableForResponse]);

      const cohosts = getOpportunityAcceptedCohosts(
        cohostApplicationsResponse
      ).map((cohost) => cohost.name);

      const hostNames = useMemo(() => {
        const hosts = company
          ? [company.name, ...cohosts]
          : [student_society.name, ...cohosts];
        return hosts.join(', ');
      }, [company, student_society, cohosts]);

      const avatarFields = getUserAvatarFields({ company });
      const startDateValue =
        isEvent && start_date
          ? format(new Date(start_date), 'MMM d, h:mma')
          : null;

      const location = useMemo(() => {
        if (!isEvent) return;
        if (is_online) return tc('virtual');

        const place = parseResponse(placeResponse);
        if (!place) return null;
        const { city, country } = place;
        return city ? city : country;
      }, [isEvent, placeResponse, is_online]);

      const applicationDeadline = useMemo(() => {
        if (!application_deadline || isExpired || isEventEnded) return null;
        const applicationDeadlineTimestamp = new Date(application_deadline);
        const now = new Date();
        const diff = differenceInDays(applicationDeadlineTimestamp, now);
        if (diff >= 30) return null;
        return <ApplicationDeadline date={application_deadline} />;
      }, [application_deadline, isExpired, isEventEnded]);

      const labelsAvailable =
        minimumSalary ||
        maximumSalary ||
        applicationDeadline ||
        kind ||
        location ||
        isEventEnded;

      return (
        <Link
          href={url}
          underlineOnHover={false}
          className={styles.link}
          target="_blank"
        >
          <div className={cx(styles.container, className)}>
            {avatarFields && showOrganization && (
              <HuzzleUserAvatar user={avatarFields} />
            )}
            <div className={styles.main}>
              <div
                className={cx(styles.head, { [styles.ended]: isEventEnded })}
              >
                {showFeaturedTag && featured && (
                  <div className={styles.tags}>
                    <div className={styles.tag}>{tc('featured')}</div>
                  </div>
                )}

                {startDateValue ? (
                  <p className={styles['event-time']}>{startDateValue}</p>
                ) : null}
                <p className={styles.title}>{title}</p>
                <div className={styles['info']}>
                  {showOpportunityType && <span>{touchpointable_type}</span>}
                  {showOpportunityType &&
                    (showOrganization ||
                      (showMultipleHosts && cohosts.length > 0)) && (
                      <span className={styles.dot}>•</span>
                    )}
                  {showOrganization && <span>{hostNames}</span>}
                  {!showOrganization &&
                    showMultipleHosts &&
                    cohosts.length > 0 && <span>{hostNames}</span>}
                </div>
              </div>
              {labelsAvailable && (
                <div className={styles.labels}>
                  {kind ? <Chip size="small">{kind}</Chip> : null}
                  {minimumSalary || maximumSalary ? (
                    <OpportunitySalary
                      size="small"
                      isTertiaryType
                      minimumSalary={minimumSalary}
                      maximumSalary={maximumSalary}
                      payCurrency={pay_currency}
                    />
                  ) : null}
                  {location ? <Chip size="small">{location}</Chip> : null}
                  {applicationDeadline}
                  {isEventEnded && (
                    <Chip
                      size="small"
                      type="critical"
                      className={styles['ended-chip']}
                    >
                      <Icon
                        size="xsmallplus"
                        iconName="icon_hourglass-outline"
                      />
                      <span>{tc('ended')}</span>
                    </Chip>
                  )}
                </div>
              )}
              {isEvent && !(isEventEnded || isExpired) && (
                <EventApplicants
                  application_count={applications}
                  avatar_urls={avatar_urls}
                  className={styles.applications}
                />
              )}
            </div>
            {eventBannerUrl && (
              <Image
                src={eventBannerUrl}
                className={cx(styles['event-banner'], {
                  [styles.grayscaled]: isEventEnded,
                })}
              />
            )}
          </div>
        </Link>
      );
    }
  );

OrganizationOpportunityCard.displayName = 'OrganizationOpportunityCard';
