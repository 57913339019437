import React, { forwardRef } from 'react';
import cx from 'classnames';

import { useApp } from 'lib/contexts/save-in-app-context';

import styles from './Page.module.scss';

export type PageProps = {
  children: React.ReactNode;
  hasBannerAbove?: boolean;
  className?: string;
};

const Page = forwardRef<HTMLElement, PageProps>(
  ({ className, hasBannerAbove, children }, ref) => {
    const { isHeaderVisible, isFooterVisible } = useApp();

    return (
      <section
        ref={ref}
        className={cx(styles.page, className, {
          [styles['header-only']]: isHeaderVisible && !isFooterVisible,
          [styles['footer-only']]: !isHeaderVisible && isFooterVisible,
          [styles['header-and-footer']]: isHeaderVisible && isFooterVisible,
          [styles['has-banner-above']]: hasBannerAbove,
        })}
      >
        {children}
      </section>
    );
  }
);

export default Page;

Page.displayName = 'Page';
