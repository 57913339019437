import { TOpportunity } from 'lib/models/opportunity';

export const parseOpportunityAiFaqs = (aiFaqs: TOpportunity['ai_faqs']) => {
  if (!aiFaqs) return [];

  // Replace all different line break formats with a common delimiter
  let standardizedInput = aiFaqs?.replace(/<br>|\n\n|\n/g, '\n');
  // Replace "---" with an empty string
  standardizedInput = standardizedInput.replace(/---/g, '');
  // const regex = /Question: (.*?)\nAnswer: (.*?)(?=\nQuestion:|$)/gs;
  const regex =
    /(?:\*\*Question:\*\*|Question:) (.*?)\n(?:\*\*Answer:\*\*|Answer:) (.*?)(?=\n(?:\*\*Question:\*\*|Question:)|$)/gs;
  let match;
  const qaList = [];

  while ((match = regex.exec(standardizedInput)) !== null) {
    const question = match[1].trim();
    const answer = match[2].trim();
    qaList.push({ question, answer });
  }
  return qaList;
};
